window.onload = function () {
  //menu animation
  var el = document.querySelector(".nav-icon", ".nav-icon-mobile");
  var elx = document.querySelector(".nav-icon-mobile");
  var element = document.querySelector(".links");
  var elementz = document.querySelector(".body");

  var elements = document.querySelectorAll(".nav-icon, .nav-icon-mobile");

  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", function () {
      el.classList.toggle("open");
      elx.classList.toggle("open");
      if (element.classList.contains("m-open")) {
        element.classList.remove("m-open");

        // menu fix
        function resize() {
          var heights = 0;
          element.style.height = heights;
        }
        resize();

        window.onresize = function () {
          resize();
        };
      } else {
        setTimeout(function () {
          element.classList.add("m-open");

          // menu fix
          function resize() {
            var heights = window.innerHeight;
            element.style.height = heights + 60 + "px";
          }
          resize();

          window.onresize = function () {
            resize();
          };
        }, 1);
      }
      elementz.classList.toggle("overflow-none");
    });
  }

  //fadein effect
  let elementsArray = document.querySelectorAll(".fade-in");
  //console.log(elementsArray);
  window.addEventListener("scroll", fadeIn);
  function fadeIn() {
    for (var i = 0; i < elementsArray.length; i++) {
      var elem = elementsArray[i];
      var distInView =
        elem.getBoundingClientRect().top - window.innerHeight + 20;
      if (distInView < 0) {
        elem.classList.add("inView");
      } else {
        elem.classList.remove("inView");
      }
    }
  }
  fadeIn();

  // smooth scroll
  const links = document.querySelectorAll(".scroll");

  for (const link of links) {
    link.addEventListener("click", clickHandler);
  }

  function clickHandler(e) {
    e.preventDefault();
    const href = this.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;

    scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  }
};
